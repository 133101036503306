export const INSERT_INVESTMENT_REQUEST = 'INSERT_INVESTMENT_REQUEST';
export const UPDATE_INVESTMENT_REQUEST = 'UPDATE_INVESTMENT_REQUEST';
export const GET_INVESTMENT_REQUEST = 'GET_INVESTMENT_REQUEST';
export const LIST_INVESTMENT_REQUEST = 'LIST_INVESTMENT_REQUEST';
export const USER_INVESTMENT_REQUEST = 'USER_INVESTMENT_REQUEST';
export const REMOVE_INVESTMENT_REQUEST = 'REMOVE_INVESTMENT_REQUEST';
export const INTERESTED_INVESTMENT_REQUEST = 'INTERESTED_INVESTMENT_REQUEST';

export const INSERT_INVESTMENT_SUCCESS = 'INSERT_INVESTMENT_SUCCESS';
export const UPDATE_INVESTMENT_SUCCESS = 'UPDATE_INVESTMENT_SUCCESS';
export const GET_INVESTMENT_SUCCESS = 'GET_INVESTMENT_SUCCESS';
export const LIST_INVESTMENT_SUCCESS = 'LIST_INVESTMENT_SUCCESS';
export const USER_INVESTMENT_SUCCESS = 'USER_INVESTMENT_SUCCESS';
export const REMOVE_INVESTMENT_SUCCESS = 'REMOVE_INVESTMENT_SUCCESS';
export const INTERESTED_INVESTMENT_SUCCESS = 'INTERESTED_INVESTMENT_SUCCESS';
