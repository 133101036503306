import React from 'react';
import {Container} from 'react-bootstrap';
const Home = (props) => {
    return (
    <Container fluid>
      <h1>Nothing Yet</h1>
    </Container>    
    )
}

export default Home;