export const INSERT_PRODUCT_REQUEST = 'INSERT_PRODUCT_REQUEST';
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const LIST_PRODUCT_REQUEST = 'LIST_PRODUCT_REQUEST';
export const COMPANIES_PRODUCT_REQUEST = 'COMPANIES_PRODUCT_REQUEST';
export const REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST';
export const INIT_PRODUCT_REQUEST = "INIT_PRODUCT_REQUEST";

export const INSERT_PRODUCT_SUCCESS = 'INSERT_PRODUCT_SUCCESS';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const LIST_PRODUCT_SUCCESS = 'LIST_PRODUCT_SUCCESS';
export const COMPANIES_PRODUCT_SUCCESS = 'COMPANIES_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
