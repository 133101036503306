export const INSERT_REDEEM_REQUEST = 'INSERT_REDEEM_REQUEST';
export const UPDATE_REDEEM_REQUEST = 'UPDATE_REDEEM_REQUEST';
export const GET_REDEEM_REQUEST = 'GET_REDEEM_REQUEST';
export const USER_REDEEM_REQUEST = 'USER_REDEEM_REQUEST';
export const FEE_REDEEM_REQUEST = 'FEE_REDEEM_REQUEST';

export const INSERT_REDEEM_SUCCESS = 'INSERT_REDEEM_SUCCESS';
export const UPDATE_REDEEM_SUCCESS = 'UPDATE_REDEEM_SUCCESS';
export const GET_REDEEM_SUCCESS = 'GET_REDEEM_SUCCESS';
export const USER_REDEEM_SUCCESS = 'USER_REDEEM_SUCCESS';
export const FEE_REDEEM_SUCCESS = 'FEE_REDEEM_SUCCESS';
