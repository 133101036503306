export const INSERT_SHARES_REQUEST = 'INSERT_SHARES_REQUEST';
export const GET_SHARES_REQUEST = 'GET_SHARES_REQUEST';
export const USER_SHARES_REQUEST = 'USER_SHARES_REQUEST';
export const UPDATE_SHARES_REQUEST = 'UPDATE_SHARES_REQUEST';
export const BOUGHT_SHARES_REQUEST = 'BOUGHT_SHARES_REQUEST';
export const COMPANIES_SHARES_REQUEST = 'COMPANIES_SHARES_REQUEST';
export const INSERT_SHARES_SUCCESS = 'INSERT_SHARES_SUCCESS';
export const GET_SHARES_SUCCESS = 'GET_SHARES_SUCCESS';
export const USER_SHARES_SUCCESS = 'USER_SHARES_SUCCESS';
export const UPDATE_SHARES_SUCCESS = 'UPDATE_SHARES_SUCCESS';
export const BOUGHT_SHARES_SUCCESS = 'BOUGHT_SHARES_SUCCESS';
export const COMPANIES_SHARES_SUCCESS = 'COMPANIES_SHARES_SUCCESS';
