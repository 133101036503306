export const INIT_GDB_COMPANIES_REQUEST = 'INIT_GDB_COMPANIES_REQUEST';
export const GET_GDB_COMPANIES_REQUEST = 'GET_GDB_COMPANIES_REQUEST';
export const LIST_GDB_COMPANIES_REQUEST = 'LIST_GDB_COMPANIES_REQUEST';
export const SEARCH_GDB_COMPANIES_REQUEST = 'SEARCH_GDB_COMPANIES_REQUEST';
export const MAIL_GDB_COMPANIES_REQUEST = 'MAIL_GDB_COMPANIES_REQUEST';

export const INSERT_GDB_COMPANIES_SUCCESS = 'INSERT_GDB_COMPANIES_SUCCESS';
export const GET_GDB_COMPANIES_SUCCESS = 'GET_GDB_COMPANIES_SUCCESS';
export const LIST_GDB_COMPANIES_SUCCESS = 'LIST_GDB_COMPANIES_SUCCESS';
export const SEARCH_GDB_COMPANIES_SUCCESS = 'SEARCH_GDB_COMPANIES_SUCCESS';
export const MAIL_GDB_COMPANIES_SUCCESS = 'MAIL_GDB_COMPANIES_SUCCESS';
