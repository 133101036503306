import React, {useState, useEffect} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LTransaction from '../../components/Transaction/List';
const TransactionLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    useEffect(() => {
        if (props.isMenu === true) {
            setIndex(0);
        }
    }, [props])

    return (
    <>    
    <Container fluid className={'ew-investment'}>
        <Row className={'align-items-center'}>
            <Col md={3} className={' d-flex align-items-center'}>
                <Row className={'justify-content-center'}>
                    <Col md={9} className={'justify-content-center d-flex align-items-center'}>    
                        {show === false && <Spinner color={'#BB82FD'} />}
                        <Image src={showSvg} fluid onLoad={() => setShow(true)}/>
                    </Col>
                </Row>       
            </Col>
            {index === 0 && <Col md={9}><LTransaction {...props} /></Col>}
        </Row>
    </Container>
    </>
    );
}

export default TransactionLayout;