export const INSERT_DOMAIN_REQUEST = 'INSERT_DOMAIN_REQUEST';
export const UPDATE_DOMAIN_REQUEST = 'UPDATE_DOMAIN_REQUEST';
export const GET_DOMAIN_REQUEST = 'GET_DOMAIN_REQUEST';
export const LIST_DOMAIN_REQUEST = 'LIST_DOMAIN_REQUEST';
export const USER_DOMAIN_REQUEST = 'USER_DOMAIN_REQUEST';
export const REMOVE_DOMAIN_REQUEST = 'REMOVE_DOMAIN_REQUEST';
export const TYPE_DOMAIN_REQUEST = 'TYPE_DOMAIN_REQUEST';

export const INSERT_DOMAIN_SUCCESS = 'INSERT_DOMAIN_SUCCESS';
export const UPDATE_DOMAIN_SUCCESS = 'UPDATE_DOMAIN_SUCCESS';
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const LIST_DOMAIN_SUCCESS = 'LIST_DOMAIN_SUCCESS';
export const USER_DOMAIN_SUCCESS = 'USER_DOMAIN_SUCCESS';
export const REMOVE_DOMAIN_SUCCESS = 'REMOVE_DOMAIN_SUCCESS';
export const TYPE_DOMAIN_SUCCESS = 'TYPE_DOMAIN_SUCCESS';
