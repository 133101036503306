export const INIT_PARTNERS_REQUEST = 'INIT_PARTNERS_REQUEST';
export const INSERT_PARTNERS_REQUEST = 'INSERT_PARTNERS_REQUEST';
export const UPDATE_PARTNERS_REQUEST = 'UPDATE_PARTNERS_REQUEST';
export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const LIST_PARTNERS_REQUEST = 'LIST_PARTNERS_REQUEST';
export const USER_PARTNERS_REQUEST = 'USER_PARTNERS_REQUEST';
export const REMOVE_PARTNERS_REQUEST = 'REMOVE_PARTNERS_REQUEST';
export const INTERESTED_PARTNERS_REQUEST = 'INTERESTED_PARTNERS_REQUEST';

export const INSERT_PARTNERS_SUCCESS = 'INSERT_PARTNERS_SUCCESS';
export const UPDATE_PARTNERS_SUCCESS = 'UPDATE_PARTNERS_SUCCESS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const LIST_PARTNERS_SUCCESS = 'LIST_PARTNERS_SUCCESS';
export const USER_PARTNERS_SUCCESS = 'USER_PARTNERS_SUCCESS';
export const REMOVE_PARTNERS_SUCCESS = 'REMOVE_PARTNERS_SUCCESS';
export const INTERESTED_PARTNERS_SUCCESS = 'INTERESTED_PARTNERS_SUCCESS';
