export const INSERT_PROPOSAL_REQUEST = 'INSERT_PROPOSAL_REQUEST';
export const UPDATE_PROPOSAL_REQUEST = 'UPDATE_PROPOSAL_REQUEST';
export const GET_PROPOSAL_REQUEST = 'GET_PROPOSAL_REQUEST';
export const LIST_PROPOSAL_REQUEST = 'LIST_PROPOSAL_REQUEST';
export const USER_PROPOSAL_REQUEST = 'USER_PROPOSAL_REQUEST';
export const REMOVE_PROPOSAL_REQUEST = 'REMOVE_PROPOSAL_REQUEST';

export const INSERT_PROPOSAL_SUCCESS = 'INSERT_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_SUCCESS = 'UPDATE_PROPOSAL_SUCCESS';
export const GET_PROPOSAL_SUCCESS = 'GET_PROPOSAL_SUCCESS';
export const LIST_PROPOSAL_SUCCESS = 'LIST_PROPOSAL_SUCCESS';
export const USER_PROPOSAL_SUCCESS = 'USER_PROPOSAL_SUCCESS';
export const REMOVE_PROPOSAL_SUCCESS = 'REMOVE_PROPOSAL_SUCCESS';