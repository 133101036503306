export const INSERT_CLIENTS_REQUEST = 'INSERT_CLIENTS_REQUEST';
export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const LIST_CLIENTS_REQUEST = 'LIST_CLIENTS_REQUEST';
export const PARTNERS_CLIENTS_REQUEST = 'PARTNERS_CLIENTS_REQUEST';
export const REMOVE_CLIENTS_REQUEST = 'REMOVE_CLIENTS_REQUEST';
export const INIT_CLIENTS_REQUEST = "INIT_CLIENTS_REQUEST";

export const INSERT_CLIENTS_SUCCESS = 'INSERT_CLIENTS_SUCCESS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const LIST_CLIENTS_SUCCESS = 'LIST_CLIENTS_SUCCESS';
export const PARTNERS_CLIENTS_SUCCESS = 'PARTNERS_CLIENTS_SUCCESS';
export const REMOVE_CLIENTS_SUCCESS = 'REMOVE_CLIENTS_SUCCESS';
